<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title> Prescription Service</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs
          v-model="labTabs"
          right
          show-arrows
          style="max-width: 50%"
          @change="changeTab"
      >
        <v-tab key="select"> select</v-tab>
        <v-tab key="response"> response</v-tab>
      </v-tabs>
      <v-btn color="primary" small text @click.stop="detailDialog = true">
        details
      </v-btn>
      <PrescribeDetail
          :detailDialog="detailDialog"
          :myId="id"
          @closeDetail="closeDetail"
      />

      <v-btn color="primary" icon text @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-tabs-items
        v-model="labTabs"
        style="background-color: #e9f0f6; margin-top: 4px"
    >
      <v-tab-item key="select">
        <v-row style="margin: 10px 0">
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-text>
                <v-select
                    v-model="find.regionsId"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    label="select region/state"
                    return-object
                ></v-select>
                <v-select
                    v-model="find.districtsId"
                    :disabled="disabled"
                    :items="districtList"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="select district"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <div
                    style="
                    display: block;
                    width: 100%;
                    text-align: right;
                    padding: 0 4px 0 0;
                    font-size: 0.8em;
                    color: #b0b0b0;
                  "
                >
                  {{ listSize > 0 ? listSize + " Shop Count" : "" }}
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" style="padding-top: 23px">
            <v-data-iterator :items="pharmaList.medics">
              <template v-slot:default="{ items }">
                <v-row>
                  <v-col
                      v-for="item in items"
                      :key="item.id"
                      cols="12"
                      sm="12"
                      style="padding: 1px 0 0"
                  >
                    <v-card outlined style="padding-bottom: 3px">
                      <v-card-actions class="pricing">
                        <ul class="pricingright">
                          <li class="title">{{ item.name }}</li>
                          <li class="subtitle">
                            {{ item.business_name }}
                          </li>
                          <li class="subtitle">
                            {{ item.physical_location }}
                          </li>
                          <li>
                            <v-rating
                                :value="item.rating"
                                dense
                                readonly
                                small
                            ></v-rating>
                          </li>
                        </ul>
                        <v-spacer></v-spacer>
                        <ul class="pricingleft">
                          <li class="notify">
                            {{ item.available_items }} of {{ item.total_items }}
                            ITEM(S)
                            <div class="price">
                              {{ currency(totalCost(item.items)) }}
                            </div>
                          </li>
                          <li>
                            <v-row>
                              <v-col>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="info"
                                        text
                                        x-small
                                        v-on="on"
                                        @click.stop="
                                        $set(viewDialog, item.id, true)
                                      "
                                    >
                                      view
                                    </v-btn>
                                  </template>
                                  <span>View Prescription options</span>
                                </v-tooltip>
                                <PrescribeView
                                    v-if="viewDialog[item.id]"
                                    :medic="item.medics_available_timesid"
                                    :myData="JSON.stringify(item.items)"
                                    :myId="item.id"
                                    :name="item.name"
                                    :viewDialog="viewDialog[item.id]"
                                    @closeView="closeView"
                                />
                              </v-col>
                              <v-col>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                        :disabled="item.is_requested"
                                        color="success"
                                        text
                                        x-small
                                        v-on="on"
                                        @click.stop="
                                        $set(buyDialog, item.id, true)
                                      "
                                    >
                                      offer
                                    </v-btn>
                                  </template>
                                  <span>Make an offer to Seller</span>
                                </v-tooltip>
                                <PrescribeBuy
                                    v-if="buyDialog[item.id]"
                                    :buyDialog="buyDialog[item.id]"
                                    :medic="item.medics_available_timesid"
                                    :myId="item.id"
                                    :name="item.name"
                                    @closeBuy="closeBuy"
                                    @submitBuy="submitBuy"
                                />
                              </v-col>
                              <v-col>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="indigo"
                                        disabled
                                        text
                                        x-small
                                        v-on="on"
                                        @click.stop="
                                        $set(chatDialog, item.id, true)
                                      "
                                    >
                                      chat
                                    </v-btn>
                                  </template>
                                  <span>Chat with Seller</span>
                                </v-tooltip>
                                <PrecribeChat
                                    v-if="chatDialog[item.id]"
                                    :chatDialog="chatDialog[item.id]"
                                    :medic="item.medics_available_timesid"
                                    :myId="item.id"
                                    :name="item.name"
                                    @closeChat="closeChat"
                                    @submitChat="submitChat"
                                />
                              </v-col>
                            </v-row>
                          </li>
                        </ul>
                        <br/>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item key="response">
        <v-card>
          <v-card-text>
            <v-data-table
                :headers="subheaders"
                :items="submittedList"
                :items-per-page="5"
                :sort-desc="[true]"
            >
              <template v-slot:item.subsidiary.rating="{ item }">
                <v-rating
                    :value="item.subsidiary.rating"
                    dense
                    readonly
                    small
                ></v-rating>
              </template>

              <template v-slot:item.business_name="{ item }">
                {{ item.business_name | capitalize }}
              </template>

              <template v-slot:item.subsidiary.name="{ item }">
                {{ item.subsidiary.name | capitalize }}
              </template>

              <template v-slot:item.accept="{ item }">
                {{ item.accept ? "Yes" : "No" }}
              </template>

              <template v-slot:item.done="{ item }">
                {{ item.done ? "Yes" : "No" }}
              </template>

              <template v-slot:item.total="{ item }">
                {{ currency(item.total) }}
              </template>

              <template v-slot:item.id="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        color="info"
                        text
                        x-small
                        v-on="on"
                        @click.stop="
                        $set(viewRespDialog, item.subsidiary.id, true)
                      "
                    >
                      view
                    </v-btn>
                  </template>
                  <span>View Prescription</span>
                </v-tooltip>
                <PrescribeResponseView
                    v-if="viewRespDialog[item.subsidiary.id]"
                    :medic="parseInt(_decode(id))"
                    :myId="item.subsidiary.id"
                    :name="item.subsidiary.name"
                    :viewRespDialog="viewRespDialog[item.subsidiary.id]"
                    @closeRespView="closeRespView"
                />
                <v-btn
                    :disabled="!item.accept"
                    color="info"
                    text
                    x-small
                    @click="checkout(item.id, item.total)"
                >
                  pay now
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils.js";
import PrescribeDetail from "../../../components/patient/request/prescribe/detail.vue";
import PrescribeView from "../../../components/appointments/prescribe/view.vue";
import PrescribeResponseView from "../../../components/appointments/prescribe/view_response.vue";
import PrescribeBuy from "../../../components/appointments/prescribe/buy.vue";
import PrecribeChat from "../../../components/appointments/prescribe/chat.vue";

export default {
  name: "follow_prescription",
  props: {id: {type: String}},
  components: {
    PrescribeDetail,
    PrescribeView,
    PrescribeResponseView,
    PrescribeBuy,
    PrecribeChat,
  },
  mixins: [Utils],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    labTabs: null,
    detailDialog: false,
    headers: [
      {text: "subscriber", value: "subscriber.business_name"},
      {text: "rating", value: "subscriber.ratings", align: "center"},
      {text: "action", value: "action", align: "center"},
    ],
    submittedList: [],
    subheaders: [
      {text: "business name", value: "business_name"},
      {text: "seller", value: "subsidiary.name"},
      {text: "rating", value: "subsidiary.rating", align: "center"},
      {text: "done", value: "done", align: "center"},
      {text: "accepted", value: "accept", align: "center"},
      {text: "cost", value: "total"},
      {text: "action", value: "id", align: "center"},
    ],
    states: [],
    districtList: [],
    pharmaList: [],
    find: {
      regionsId: 0,
      districtsId: 0,
    },
    disabled: true,
    viewDialog: {},
    viewRespDialog: {},
    buyDialog: {},
    chatDialog: {},
    listSize: 0,
  }),
  watch: {
    "find.regionsId": function (i) {
      console.log(i);
      if (i.id > 0) {
        this.districts(i.id);
        this.disabled = false;
      }
    },
    "find.districtsId": function (a) {
      console.log(a);
      if (a > 0) {
        this.getList();
      }
    },
  },
  created() {
    this.finished();
    this.regions();
    this.submitted();
  },
  methods: {
    finished() {
      this.loading = true;
      let id = parseInt(this._decode(this.id));
      Restful.medics.treatment.prescription
          .finished(id)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              this.isEmpty = response.data;
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    regions() {
      Restful.general.regions
          .byCountry(1)
          .then((response) => {
            console.log(response.data);
            this.states = response.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    districts(id) {
      Restful.general.district
          .byRegion(id)
          .then((response) => {
            console.log(response.data);
            this.districtList = response.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    getList() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let countryid = 1;
      let regionsid = this.find.regionsId.id;
      let districtsid = this.find.districtsId;
      let data = {
        medics_available_timesid,
        countryid,
        regionsid,
        districtsid,
      };
      console.log("getList data", data);
      Restful.medics.treatment.transact.prospect.prescribe
          .findMedics(data)
          .then((response) => {
            console.log("response:", response);
            this.pharmaList = response.data;
            this.orderSize = response.data.count;
            this.orderCost = response.data.total_cost;
            this.listSize = response.data.medics.length;
            console.log(response.data.medics.length);
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    totalCost(items) {
      let sum = items.reduce((acc, item) => acc + item.sub_total, 0);
      return sum;
    },
    changeTab(e) {
      console.log("e", e);
      console.log("clicked", e);
      switch (e) {
        case 0:
          // console.log('select');
          break;
        case 1:
          this.submitted();
          break;
      }
    },
    submitted() {
      let id = parseInt(this._decode(this.id));
      console.log("submitted id", id);
      Restful.medics.treatment.transact.prospect.prescribe
          .submitted(id)
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
              this.submittedList = response.data;
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    refresh() {
      this.submitted();
    },
    closeDetail(p) {
      this.detailDialog = p.state;
      // this.$set(this.detailDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRespView(p) {
      this.$set(this.viewRespDialog, p.id, p.state);
    },
    closeBuy(p) {
      this.$set(this.buyDialog, p.id, p.state);
    },
    submitBuy(p) {
      this.snack.bar = true;
      console.log("p", p);
      let self = this;
      Restful.medics.treatment.transact.prospect.prescribe
          .create(p.data)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.snack.color = "success";
              this.snack.text = response.data;
              this.getList();
            }
            this.finished();
            this.regions();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.buyDialog, p.id, p.state);
    },
    closeChat(p) {
      this.$set(this.chatDialog, p.id, p.state);
    },
    submitChat(p) {
      this.$set(this.chatDialog, p.id, p.state);
    },
    checkout(myId, total) {
      let medicsAvailableId = parseInt(this._decode(this.id));
      let type = "pharma";
      let price = total;
      let id = myId;
      let service_type = "pharma";
      let data = {id, service_type, medicsAvailableId, type, price};
      console.log("data: ", data);
      let encode = this._encode(JSON.stringify(data));
      console.log('encode', encode)
      this.$router.push({name: "pay.now", params: {id: encode}});
    },
  },
};
</script>

<style scoped></style>
